import Repository from "./Repository";
import { ADMIN_REPOSITORY, GENERAL_NAME, SUPPORT_FLOW, LINE_REPOSITORY } from "@/constants/";

export default {
    async healthGuidanceReceptionDetail(id) {
        try {
            return await Repository.get(`/admin/health-guidance-reception/detail/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async submitDeleteMemo(id) {
        try {
            return await Repository.get(`/admin/delete-message-log-memo/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthResult(patient_id) {
        try {
            return await Repository.get(`/diagnostic-result/${patient_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    getListSupportPlace(insurer_code) {
        try {
            if (insurer_code) {
                return Repository.get(`${SUPPORT_FLOW}/support-place/?insurer_code=${insurer_code}`)
            }
            return Repository.get(`${SUPPORT_FLOW}/support-place`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    getListRequester(data) {
        try {
            return Repository.get(`${SUPPORT_FLOW}/requester`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    getListSupportPerson() {
        try {
            return Repository.get(`${SUPPORT_FLOW}/support-person`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    createSupportPlace(bodyRequest = {}) {
        try {
            return Repository.post(`${GENERAL_NAME}/create`, bodyRequest)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    updateSupportPlace(bodyRequest) {
        try {
            return Repository.post(`${GENERAL_NAME}/update/${bodyRequest.id}`, bodyRequest)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    deleteSupportPlace(bodyRequest) {
        try {
            return Repository.post(`${GENERAL_NAME}/delete/${bodyRequest.id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getReceptionFlowSegments(reception_id) {
        try {
            return await Repository.get(`/support-flow/reception/${reception_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSegmentEval(segment_id) {
        try {
            return await Repository.get(`/support-flow/segment-evals/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    /**
     * 初回支援形態取得.
     *
     * @returns
     */
    async getMasterFormSupport() {
        try {
            return await Repository.get("/support-flow/master-form-support")
        } catch (e) {
            return this.setErrors(e);
        }
    },
    /**
     * 期間マスタ取得.
     * @returns
     */
    async getIntendDateList() {
        try {
            return await Repository.get("/support-flow/intend-date-list")
        } catch (e) {
            return this.setErrors(e);
        }
    },
    /**
     * 継続支援 支援形態取得.
     */
    async getContinueSupportFormList(type = "") {
        try {
            return await Repository.get(`/support-flow/continue-support-form-list/${type}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async middleTerminate(support_flow_id, data) {
        try {
            return await Repository.post(`/support-flow/middle-terminate/${support_flow_id}`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async rejectFirstReview(support_flow_id, data) {
        try {
            return await Repository.post(`/support-flow/reject-first-review/${support_flow_id}`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async clearAssignTask(segment_id) {
        try {
            return await Repository.post(`/support-flow/clear-assign-task-segment/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async assignTask(segment_id, data) {
        try {
            return await Repository.post(`/support-flow/assign-task-segment/${segment_id}`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async updateSegmentTels(segment_id, data) {
        try {
            return await Repository.post(`/support-flow/segment-tels/${segment_id}`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async storeSegmentTels(segment_id, data) {
        try {
            return await Repository.post(`/support-flow/store-segment-tels/${segment_id}`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async recreateFlowFunc(flow_id, data) {
        try {
            return await Repository.post("/support-flow/recreate-flow", data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async createSupportFlow(data) {
        try {
            return await Repository.post("/support-flow/save", data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getFlowDetail(flow_id) {
        try {
            return await Repository.get(`/support-flow/detail/${flow_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async btnFormSupport(segment_id, action) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-support-flow-segment/${segment_id}/update-segment-evals/${action}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    /**
     * 直前支援をもとに評価
     *
     * @param {*} preview_segment_id
     * @param {*} segment_id
     * @returns
     */
    async copySegmentEval(preview_segment_id, segment_id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/segment-copy-evals/${preview_segment_id}/${segment_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async listContactTemplate() {
        try {
            return await Repository.get("list-contact-template");
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async listFormTemplate() {
        try {
            return await Repository.get("list-form-template");
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getGenerateLink(data) {
        try {
            return await Repository.post("generate-link", data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async segmentSendMail(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/segment-send-mail`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async segmentSendMemo(data, segment_id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/${segment_id}/segment-send-memo`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async segmentSendSMS(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/segment-send-sms`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async segmentSendLineMsg(data) {
        try {
            return await Repository.post(`${LINE_REPOSITORY}/segment-send-messages`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContactLine(patient_line) {
        try {
            return await Repository.get(`${LINE_REPOSITORY}/get-contact-line?patient_line=${patient_line}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContactLineOld(patient_id) {
        try {
            return await Repository.get(`${LINE_REPOSITORY}/get-contact-line-old?patient_id=${patient_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async deleteContactLine(line_user_id) {
        try {
            return await Repository.delete(`${LINE_REPOSITORY}/delete-contact-line/${line_user_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async syncLineContact(data) {
        try {
            return await Repository.post(`${LINE_REPOSITORY}/sync-contact-line`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getLogMessageLineByPatientId({patientId, segmentId}) {
        try {
            return await Repository.get(`${LINE_REPOSITORY}/get-log-message-patient?patient_id=${patientId}&segment_id=${segmentId}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getMessageLogList(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/list-message-log/${segment_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSegmentDetail(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${segment_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async settingStatusSegment(dataSegment) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-support-flow-segment/setting-status`, dataSegment);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
