<template>

<!--    :class="{-->
<!--    'bg-first-support': checkSupportFirst(segment),-->
<!--    'bg-a-support': checkSupportA(segment),-->
<!--    'bg-b-support': checkSupportB(segment),-->
<!--    'bg-mid-eval': checkMidEval(segment),-->
<!--    'bg-final-support': checkSupportFinal(segment),-->
<!--    'bg-overdue': segment?.support_date < formatTime(new Date(), 'YYYY-MM-DD')-->
<!--    }"-->
    <span v-if="segment">
        {{prefix}}
        <span>
            <span v-if="!checkSupportFirst(segment) && !checkSupportFinal(segment)">{{segment?.support_flow_seq}}.</span>
            <span v-if="codeStatusSupport"> {{codeStatusSupport[parseInt(segment?.health_support_form?.support_form_div)]}}</span>
            <span v-if="checkSplitFirstSupportFirst(segment)">&nbsp;(分割実施 1回目)</span>
            <span v-if="checkSplitFirstSupportSecond(segment)">&nbsp;(分割実施 2回目)</span>
            <span class="red--text" v-if="checkMidEval(segment)"> 中間評価</span>
          <span v-if="checkSupportFirst(segment) || checkSupportFinal(segment)">
            {{ segment?.health_support_form?.support_form_name_disp_02 }}
          </span>
          <span v-else-if="checkSupportA(segment) || checkSupportB(segment) || checkMidEval(segment)">
            {{ segment?.health_support_form?.support_form_name_disp_01 }}
            <span>(<span v-if="codeSupportProgressConfirm == segment?.support_progress_status">{{ segment?.support_amount }}</span><span v-else>{{segment?.scheduled_support_amount}}</span>{{ segment?.health_support_form?.support_amount_unit }}</span>)
          </span>
            <span v-if="segment?.support_stop_flag == false && segment?.support_progress_status == supportProgressStatusPlan && segment?.plan_support_datetime" class="text-schedule-date">
                計画日：{{ formatTime(segment?.plan_support_datetime, "MM/DD") }}
            </span>
            <span v-if="segment?.support_stop_flag == false && segment?.support_progress_status == supportProgressStatusIntended && segment?.scheduled_support_datetime">
                予定日：{{ formatTime(segment?.scheduled_support_datetime, "MM/DD") }}
            </span>
            <span v-if="segment?.support_stop_flag == false && segment?.support_progress_status == supportProgressStatusConfirm && segment?.support_date">
                実施日：{{ formatTime(segment?.support_date, "MM/DD") }}
            </span>
        </span>
        {{suffix}}
    </span>
    <div v-else>
        {{prefix}}{{suffix}}
    </div>
</template>

<script>
import moment from "moment";
import Repository from "../../services/api/Repository";
import {CODE_STATUS_SUPPORT} from "@/common/constants";
import mixins from "@/mixins"

export default {
    name: "SegmentTitle",
    async created() {
        this.codeStatusSupport = await this.getCodeDefine(CODE_STATUS_SUPPORT)
    },
    props: {
        segment: null,
        prefix: null,
        suffix: null,
        splitFirstSupportFlag: null,
    },
    mixins: [
        mixins
    ],
    data() {
        return {
            codeStatusSupportFirst: 10,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            codeStatusSupportFinal: 40,
            default: "Title",
            codeSupportProgressConfirm: 40,
            supportProgressStatusPlan: 10,
            supportProgressStatusIntended: 20,
            supportProgressStatusConfirm: 40,
            codeStatusSupport: null
        };
    },
    watch: {
        //
    },
    methods: {
        checkSupportFirst(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportFirst;
        },
        checkSupportA(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportA;
        },
        checkSupportB(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportB;
        },
        checkMidEval(segment) {
            return segment?.mid_eval_flg;
        },
        formatTime(value, format) {
            return moment(value).format(format);
        },
    }
}
</script>
