<template>
    <div class="support-schedule-list d-flex flex-column h-100">
        <p class="border p-1 m-0 d-flex justify-space-between align-items-center position-relative">
            <b>検索条件</b>
        </p>
        <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
                <template #activator>
                    <i class="fas fa-bars text-primary position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
                </template>
                <v-list-item>
                    <div class="search-form mb-10 border p-2 border-top-0">
                        <div class="col-12 p-0 m-0 text-right">
                            <button class="btn btn-grey text-nowrap" @click="() => {
                                this.form = JSON.parse(JSON.stringify(formOrg));
                                search();
                            }"><i class="fas fa-trash"></i> クリア</button>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-sm-12">
                                <div class="form-group">
                                    <div>
                                        <label for="">受付年度</label>
                                    </div>
                                    <div class="row">
                                        <div class="col pr-0 py-0">
                                            <date-picker
                                                    autocomplete="off"
                                                    v-model="form.contract_fiscal_year"
                                                    id="contract_fiscal_year"
                                                    name="contract_fiscal_year"
                                                    :config="optionYear"
                                                    @input="validate('contract_fiscal_year')"
                                            ></date-picker>
                                        </div>
                                        <div class="col-auto px-1 pt-2 pb-0">
                                        以降
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <span class="text-danger">{{ errorsSearch.contract_fiscal_year }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-12" v-if="listSupportPerson">
                                <div class="form-group">
                                    <label for="scheduled_support_staff_id">支援担当者</label>
                                    <select class="form-select" v-model="form.scheduled_support_staff_id" id="scheduled_support_staff_id">
                                        <option v-if="listSupportPersonOptionBlank" :value="null"></option>
                                        <option v-for="(item,index) in listSupportPerson" :value="item.id" :key="item.id + 'scheduled_support_staff_id' + index">{{item.user_name_disp}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12">
                                <label for="">支援予定日</label>
                                <div class="row no-gutters">
                                    <div class="col-5">
                                        <date-picker
                                                autocomplete="off"
                                                v-model="form.scheduled_support_date_from"
                                                id="from_date"
                                                name="from_date"
                                                :config="optionFromDate"
                                                @input="validate('scheduled_support_date_from');validate('scheduled_support_date_to')"
                                        ></date-picker>
                                    </div>
                                    <div class="col-2 d-flex justify-content-center align-items-center">-</div>
                                    <div class="col-5">
                                        <date-picker
                                                autocomplete="off"
                                                v-model="form.scheduled_support_date_to"
                                                id="to_date"
                                                name="from_date"
                                                :config="optionToDate"
                                                @input="validate('scheduled_support_date_to')"
                                        ></date-picker>
                                    </div>
                                    <span class="text-danger">{{errorsSearch.scheduled_support_date_from}}</span>
                                    <span class="text-danger">{{errorsSearch.scheduled_support_date_to}}</span>
                                    <span class="text-danger">{{In_ValRng_TermOver}}</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12">
                                <label for="">初回支援実施日</label>
                                <div class="row no-gutters">
                                    <div class="col-5">
                                        <date-picker
                                                autocomplete="off"
                                                v-model="form.first_support_date_from"
                                                id="first_support_from_date"
                                                name="first_support_from_date"
                                                :config="optionFromDate"
                                        ></date-picker>
                                    </div>
                                    <!-- <div class="col-2 d-flex justify-content-center align-items-center">-</div>
                                    <div class="col-5">
                                        <date-picker
                                                autocomplete="off"
                                                v-model="form.first_support_date_to"
                                                id="first_support_to_date"
                                                name="first_support_to_date"
                                                :config="optionToDate"
                                        ></date-picker>
                                    </div> -->
                                    <span class="text-danger">{{errorsSearch.first_support_date_from}}</span>
                                    <span class="text-danger">{{errorsSearch.first_support_date_to}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-sm-12">
                                <div class="form-group">
                                    <label for="">氏名</label>
                                    <input type="text" class="form-control" placeholder="氏名 or 氏名カナ"
                                    v-model="form.patient_name"
                                    @keyup="validate('patient_name')">
                                    <span class="text-danger">{{ errorsSearch.patient_name }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-12">
                                <div class="form-group">
                                    <label for="">利用券整理番号</label>
                                    <input type="text" class="form-control"
                                    v-model="form.use_ticket_ref_number"
                                    @keyup="validate('patient_ticket')">
                                    <span class="text-danger">{{ errorsSearch.patient_ticket }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-12">
                                <div class="form-group">
                                    <label for="">保険者</label>
                                    <select v-model="form.client_organ_code" class="form-select">
                                        <option :value="null"></option>
                                        <option
                                                v-for="(insurer,index) in insurerArr"
                                                :key="insurer.id + 'insurer' + index"
                                                :value="insurer.client_organ_code"
                                        >
                                            [{{ insurer.client_organ_code }}] {{ insurer.client_organ_name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-12">
                                <div class="form-group">
                                    <label for="health_guidance_div">保健指導区分</label>
                                    <select class="form-select" v-model="form.health_guidance_div" id="health_guidance_div">
                                        <option :value="null"></option>
                                        <option v-for="(item, key) in list_health_guidance_division" :value="key" :key="key + 'health_guidance_div'">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pb-0 mb-0">
                                支援形態
                            </div>
                            <div class="col-12">
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="first_support" v-model="form.first_support">
                                    <label for="first_support">初回支援</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="continuing_support" v-model="form.continuing_support">
                                    <label for="continuing_support">支援</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="intermediate_eval" v-model="form.intermediate_eval">
                                    <label for="intermediate_eval">中間評価</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="final_support" v-model="form.final_support">
                                    <label for="final_support">最終評価</label>
                                </div>
                            </div>
                            <div class="col-12 pb-0 mb-0">
                                支援状況
                            </div>
                            <div class="col-12">
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="first_task_unassigned" v-model="form.first_task_unassigned">
                                    <label for="first_task_unassigned">初回タスク未割当</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="schedule" v-model="form.schedule">
                                    <label for="schedule">予定</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="in_progress" v-model="form.in_progress">
                                    <label for="in_progress">実施中</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="confirm" v-model="form.confirm">
                                    <label for="confirm">確定</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="suspension_of_support" v-model="form.suspension_of_support">
                                    <label for="suspension_of_support">支援中止</label>
                                </div>
                                <button class="btn btn-success float-right" @click="search()"><i class="fas fa-search"></i> 検索</button>
                            </div>
                        </div>
                    </div>
                </v-list-item>
            </v-list-group>
        </v-list>
        <div class="row result h-100 overflow-hidden" v-if="results">
            <div class="col-12 d-flex flex-column h-100 overflow-auto" >
                <div class="segment mt-2 border" v-for="(segment, key) in results" :key="segment?.id + key" :class="processTagColor(segment, segment.flow.support_flow_status, getPrevSegmentInfo(segment))">
                    <div class="position-relative">
                        <div class="tick" v-if="parseInt(segment?.support_progress_status) === IN_PROGRESS">一時保存</div>
                        <div class="tick" v-if="parseInt(segment?.support_progress_status) === CONFIRM">確定</div>
                    </div>

                    <div class="header p-2" :class="classSegment(segment)">
                        予定：[<span class="p-0" v-if="segment.scheduled_support_datetime || segment?.scheduled_support_time">{{formatTime(segment.scheduled_support_datetime, "YYYY-MM-DD")}} {{segment?.scheduled_support_time}}</span><span v-else>-</span>]

                        (<span class="p-0" v-if="segment?.schedule_support_location_item?.item_name">{{segment?.schedule_support_location_item?.item_name}}</span><span v-else>-</span>)
                        担当者: <span class="p-0" v-if="segment?.scheduled_support_staff_id">[{{ String(segment?.scheduled_support_staff_id).padStart(7, "0") }}] {{segment?.schedule_support_staff_user?.user_name_disp}}</span><span v-else>[-]</span>
                        <template v-if="checkSplitFirstSupportFirst(segment)">
                            {{ codeStatusSupportExecDiv[codeStatusSupportFirst] }} (分割実施 1回目)
                        </template>
                        <template v-else-if="checkSplitFirstSupportSecond(segment)">
                            {{ codeStatusSupportExecDiv[codeStatusSupportFirst] }} (分割実施 2回目)
                        </template>
                        <template v-else>
                            {{  codeStatusSupport[parseInt(segment?.health_support_form?.support_form_div)] }}
                        </template>
                        <template v-if="segment?.mid_eval_flg">
                            <span>中間評価</span>
                        </template>
                        &nbsp;/
                        <span v-if="segment.support_progress_status == 10">{{ segment?.health_support_form.support_form_name_disp_02 }}</span>
                        <span v-if="segment.support_progress_status != 10">{{ segment?.health_support_form.support_form_name_disp_01 }}</span>
                        <span v-if="segment.support_progress_status == 10">{{ codeStatusSupportExecDiv[parseInt(segment?.health_support_form.support_exec_div)] }}</span>
                        <span v-if="segment?.support_stop_flag == false && typeof segment?.support_assign_memo === 'string'">
                            <i class="fas fa-pencil cursor-pointer" @click="showDialogFn(segment)"></i>
                        </span>
                    </div>
                    <p class="status p-2">{{list_support_flow_status[segment?.flow?.support_flow_status]}}</p>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-7" style="word-break: break-all;">
                          <table class="table table-bordered table-responsive">
                                <tr class="">
                                    <td class="p-1 border-top-0">{{ yearYY(segment?.flow?.reception?.contract_fiscal_year) }}年度&nbsp;(第{{ segment?.flow?.reception?.health_guidance_period }}期)&nbsp;&nbsp;[{{String(segment?.flow?.reception?.patient?.id).padStart(10, "0")}}]/{{segment?.flow?.reception?.patient?.patient_name_kana}}/{{segment?.flow?.reception?.patient?.patient_name}}&nbsp;&nbsp;{{segment?.flow?.reception?.patient?.patient_birthday}}&nbsp;&nbsp;{{list_gender[segment?.flow?.reception?.patient?.patient_gender]}}&nbsp;&nbsp;{{list_health_guidance_division[segment?.flow?.reception?.health_guidance_div]}}</td>
                                </tr>
                            </table>
                            <table class="table table-bordered table-responsive">
                                <tr>
                                    <td class="p-1 w-25 border-top-0">利用券整理番号</td>
                                    <td class="p-1 border-top-0">{{segment?.flow?.reception?.patient?.use_ticket_ref_number}}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">保険者</td>
                                    <td class="p-1">[{{segment?.flow?.reception?.patient?.client_organization?.client_organ_code}}] {{segment?.flow?.reception?.patient?.client_organization?.client_organ_name}}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">保険証(番号/記号/枝番/区分)</td>
                                    <td class="p-1">{{getInsuredCardInfo(segment)}}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">支援依頼者</td>
                                    <td class="p-1">[<span class="p-0" v-if="segment?.support_assignor_user?.id">{{String(segment?.support_assignor_user?.id).padStart(7, "0")}}</span><span class="p-0" v-else>-</span>]{{segment?.support_assignor_user?.user_name_disp}}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">初回支援実施日</td>
                                    <td class="p-1">{{segment.first_flow_segment_support_date}}</td>
                                </tr>
                                <tr>
                                    <td class="p-3"></td><td></td>
                                </tr>
                                <tr>
                                    <td class="p-1">対象者回答</td>
                                    <td class="p-1">{{ segment.message_logs_l4[0] ? formatTime(segment.message_logs_l4[0].created_at, "YYYY-MM-DD HH:mm:ss") : '' }}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">対象者返答</td>
                                    <td class="p-1">{{ segment.message_logs_l5[0] ? formatTime(segment.message_logs_l5[0].created_at, "YYYY-MM-DD HH:mm:ss") : '' }}</td>
                                </tr>
                                <tr>
                                    <td class="p-1">メッセージ</td>
                                    <td class="p-1">{{ segment.message_logs[0] ? formatTime(segment.message_logs[0].created_at, "YYYY-MM-DD HH:mm:ss") + typeOfMessage(segment) : '' }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-4 action">
                            <div class="d-flex mt-4 justify-space-around">
                                <a v-if="iconInput(segment)" @click="functionSetPagePrevious(segment,'form-support')" class="text-dark">
                                    <span class="d-flex flex-column align-items-center cursor-pointer">
                                        <i class="fas fa-mobile-screen-button fa-3x"></i>
                                        <a>入力</a>
                                    </span>
                                </a>
                                <a @click="functionSetPagePrevious(segment,'dashboard')" class="text-dark">
                                    <span class="d-flex flex-column align-items-center cursor-pointer">
                                        <i class="fas fa-book fa-3x"></i>
                                        <a>支援管理</a>
                                    </span>
                                </a>
                                <span class="d-flex flex-column align-items-center cursor-pointer" v-if="iconAssignTask(segment)" @click="taskAssignModal(segment)">
                                    <i class="fas fa-gears fa-3x"></i>
                                    <a>タスク割当</a>
                                </span>
                            </div>
                            <div class="d-flex mt-10 justify-space-around">
                                <span class="d-flex flex-column align-items-center" v-if="iconOverDue(segment)">
                                    <i class="fas fa-warning fa-3x text-danger"></i>
                                    <span class="text-danger">超過{{diffDate(segment?.scheduled_support_datetime)}}日</span>
                                </span>
                                <span class="d-flex flex-column align-items-center" v-if="iconContinueSupportDecline(segment)">
                                    <i class="fas fa-circle-exclamation fa-3x text-warning"></i>
                                    <span class="text-danger">継続支援辞退</span>
                                </span>
                                <span v-if="checkIconPhone(segment)" class="d-flex flex-column align-items-center">
                                    <i class="fas fa-phone fa-3x text-danger"></i>
                                    <span class="text-danger">督促</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog :isShow="isShowDialogAssignTask"
                    persistent
                    :maxWidth="600"
            >
                <AssignTasks
                    :segmentItem="segmentItem"
                    :listSupportPlace="listSupportPlace"
                    openSource="SupportScheduleList"
                    @closeDialog="closeDialogAssignTask"
                    @openActionAddress="onHandleOpenActionAddress"
                />
            </Dialog>
            <Dialog
                    :isShow="isShowDialogAddress"
                    persistent
                    :maxWidth="400">
                <ActionAddress
                    :addressEdit="addressEdit"
                    :insurerId="insurerId"
                    @closeDialog="onHandleCloseDialogAddress"
                    @actionSuccess="getListSupportPlace" />
            </Dialog>

            <Dialog
                :isShow="showDialog"
                :maxWidth="400"
                persistent
            >
                <v-card>
                    <v-container>
                        {{showDialogMemo}}
                        <v-card-actions class="d-flex justify-content-center">
                            <button
                                    type="button"
                                    class="btn btn-outline-primary btn-sm"
                                    @click="showDialog = false">
                                Close
                            </button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
        </div>
        <Pagination
        :total-page="totalPage"
        :current-page="currentPage"
        class="m-0"
        @onHandleChangePage="onHandleChangePage"
        />
    </div>
</template>

<script>
import mixins from "@/mixins"
import DashboardRepository from "@/services/api/DashboardRepository";
import SupportScheduleListService from "@/services/api/SupportScheduleList";
import Pagination from "@/components/common/Pagination";
import moment from "moment";
import SegmentBlock from "@/components/dashboard/SegmentBlock";
import SegmentTitle from "@/components/dashboard/SegmentTitle";
import AssignTasks from "@/components/dashboard/FlowSupport/AssignTasks";
import ActionAddress from "@/components/dashboard/FlowSupport/ActionAddress";
import Dialog from "@/components/common/Dialog";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { CODE_STATUS_SUPPORT, CODE_SUPPORT_EXEC_DIV, TOAST_TIME_OUT } from "@/common/constants";
import { formSearchSupportScheduleList } from "../../common/schemaValidation";
import {mapMutations, mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "SupportScheduleList",
    data() {
        return {
            form: {
                contract_fiscal_year: String(this.getFiscalYear()), // 受付年度
                scheduled_support_staff_id: null, // 支援担当者
                scheduled_support_date_from: moment().format("YYYY-MM-DD"), // 支援予定日
                scheduled_support_date_to: moment().add(1, "day").format("YYYY-MM-DD"), // 支援予定日
                first_support_date_from: null, // 初回支援実施日FROM
                first_support_date_to: null, // 初回支援実施日TO
                patient_name: null, // 氏名
                use_ticket_ref_number: null, // 利用券整理番号
                client_organ_code: null, // 保険者
                health_guidance_div: null, // 保健指導区分
                first_support: true,
                continuing_support: true,
                intermediate_eval: true,
                final_support: true,
                first_task_unassigned: true,
                schedule: true,
                in_progress: true,
                confirm: false,
                suspension_of_support: false
            },
            formOrg: {
                contract_fiscal_year: String(this.getFiscalYear()), // 受付年度
                scheduled_support_staff_id: null, // 支援担当者
                scheduled_support_date_from: moment().format("YYYY-MM-DD"), // 支援予定日
                scheduled_support_date_to: moment().add(1, "day").format("YYYY-MM-DD"), // 支援予定日
                first_support_date_from: null, // 初回支援実施日FROM
                first_support_date_to: null, // 初回支援実施日TO
                patient_name: null, // 氏名
                use_ticket_ref_number: null, // 利用券整理番号
                client_organ_code: null, // 保険者
                health_guidance_div: null, // 保健指導区分
                first_support: true,
                continuing_support: true,
                intermediate_eval: true,
                final_support: true,
                first_task_unassigned: true,
                schedule: true,
                in_progress: true,
                confirm: false,
                suspension_of_support: false
            },
            listSupportPerson: null,
            listSupportPersonOptionBlank: true,
            listInsurer: null,
            list_family_division: null,
            list_health_guidance_division: null,
            list_support_flow_status: null,
            list_gender: null,
            listSupportPlace: [],
            currentPage: 1,
            totalPage: 1,
            perPage: 10,
            results: [],
            //support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20, // 予定
            IN_PROGRESS: 30, // 実施中
            CONFIRM: 40, // 確定

            //health_support_flow_segments.support_exec_div
            INITIAL_SUPPORT: 10,
            CONTINUOUS_SUPPORT: 20,
            FINAL_EVALUATION: 30,

            // segment?.health_support_form.support_form_way
            FORM_OF_SUPPORT_TELEPHONE: 40,
            isShowDialogAssignTask: false,
            showDialog: false,
            showDialogMemo: null,
            insurerArr: [],
            isShowDialogAddress: false,
            addressEdit: null,
            segmentItem: null,
            insurerId: null,
            rotateNavBar: false,
            errorsSearch: {
                scheduled_support_date_from: null,
                scheduled_support_date_to: null,
                first_support_date_from: null,
                first_support_date_to: null,
                patient_name: null,
                patient_ticket: null,
                contract_fiscal_year: null
            },
            In_ValRng_TermOver: null,
        }
    },
    async created() {
        this.codeStatusSupport = await this.getCodeDefine(CODE_STATUS_SUPPORT);
        this.codeStatusSupportExecDiv = await this.getCodeDefine(CODE_SUPPORT_EXEC_DIV);
        await SupportScheduleListService.getInsurer().then((res) => {
            this.insurerArr = res.data.data;
        });

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.search()
            }
        });
    },
    async mounted() {
        await this.getListSupportPerson();
        await this.getInsurer();
        await this.getListSupportPlace();


        // in mixin method
        if (this.isSupporter() && !this.isBackOffice() && !this.isSystemAdmin()) {
            // this.find();
        }

        // 保健指導区分 00000700
        this.list_health_guidance_division = await this.getCodeDefine("00000700")
        // 支援フローステータス 00001400
        this.list_support_flow_status = await this.getCodeDefine("00001400")
        this.list_gender = await this.getCodeDefine("00000100", "acronym")

        this.list_family_division = await this.getCodeDefine("00000400")

        this.$root.$on("SupportScheduleList.ReloadDataReception", () => {
            this.find(false)
        });

        if(this.pagePrevious != undefined){
            if(this.pagePrevious.formSearch.contract_fiscal_year) {
                this.form = this.pagePrevious.formSearch;
                this.currentPage = this.pagePrevious.currentPage;
                this.perPage = this.pagePrevious.perPage;
                await this.find(false);
            }
        } else {
            if (this.isSupporter(true)) {
                await this.find(false);
            }
        }
    },
    watch: {
        // "form.first_support_date_from"() {
        //     this.validateFirstSupportTerm();
        // },
        // "form.first_support_date_to"() {
        //     this.validateFirstSupportTerm();
        // },
        "form.scheduled_support_date_to"() {
            if (this.form.scheduled_support_date_from && this.form.scheduled_support_date_to && !this.errorsSearch.scheduled_support_date_from && !this.errorsSearch.scheduled_support_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.form.scheduled_support_date_to).diff(moment(this.form.scheduled_support_date_from), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "支援予定日の範囲は180日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "form.scheduled_support_date_from"() {
            if (this.form.scheduled_support_date_from && this.form.scheduled_support_date_to && !this.errorsSearch.scheduled_support_date_from && !this.errorsSearch.scheduled_support_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.form.scheduled_support_date_to).diff(moment(this.form.scheduled_support_date_from), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "支援予定日の範囲は180日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },

        "errorsSearch.scheduled_support_date_to"() {
            if (this.form.scheduled_support_date_from && this.form.scheduled_support_date_to && !this.errorsSearch.scheduled_support_date_from && !this.errorsSearch.scheduled_support_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.form.scheduled_support_date_to).diff(moment(this.form.scheduled_support_date_from), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "支援予定日の範囲は180日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "errorsSearch.scheduled_support_date_from"() {
            if (this.form.scheduled_support_date_from && this.form.scheduled_support_date_to && !this.errorsSearch.scheduled_support_date_from && !this.errorsSearch.scheduled_support_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.form.scheduled_support_date_to).diff(moment(this.form.scheduled_support_date_from), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "支援予定日の範囲は180日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
    },
    components: {
        Pagination,
        AssignTasks,
        Dialog,
        ActionAddress
        // SegmentBlock,
        // SegmentTitle,
    },
    computed: {
        ...mapGetters({
            pagePrevious: "dashboard/getPagePrevious",
            user: "common/getUser"
        }),
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            }
        },
        optionFromDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            }
        },
        optionToDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            }
        }
    },
    mixins: [
        mixins
    ],
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            setSegment: "dashboard/setSegment",
            setPagePrevious: "dashboard/setPagePrevious",
        }),
        // validateFirstSupportTerm() {
        //     this.errorsSearch.first_support_date_from = null;
        //     this.errorsSearch.first_support_date_to = null;

        //     if (!this.form.first_support_date_from && !this.form.first_support_date_to) return true;

        //     if ((!this.form.first_support_date_from && this.form.first_support_date_to) ||
        //         (this.form.first_support_date_from && !this.form.first_support_date_to)) {
        //         this.errorsSearch.first_support_date_from = "初回支援実施日は期間(From/To)で入力してください。";
        //         return false;
        //     }

        //     if (this.form.first_support_date_from && this.form.first_support_date_to) {
        //         if (moment(this.form.first_support_date_from).isAfter(moment(this.form.first_support_date_to))) {
        //             this.errorsSearch.first_support_date_from = "初回支援実施日は開始と終了が逆転しています。";
        //         }
        //     }
        // },
        validate(field) {
            formSearchSupportScheduleList
                .validateAt(field, this.form)
                .then(() => {
                    this.errorsSearch[field] = "";
                })
                .catch((err) => {
                    this.errorsSearch[field] = err.message;
                });
        },
        functionSetPagePrevious(segment, page){
            const data = {
                formSearch: this.form,
                page: "SupportScheduleList",
                currentPage: this.currentPage,
                perPage: this.perPage
            }
            this.setPagePrevious(data);
            switch (page) {
            case "dashboard":
                this.$router.push({
                    path: `/dashboard?reception_id=${segment?.flow?.reception?.id}`
                })
                break;

            case "form-support":
                this.$router.push({ name: "mobile.form-support", query: { reception_id: segment?.flow?.reception?.id, segment_id:segment?.id } });
                break;
            default:
                break;
            }

        },
        yearYY(year) {
            return year.toString().substr(-2);
        },
        async getListSupportPerson() {
            this.listSupportPerson = await DashboardRepository.getListSupportPerson().then(res => {
                let SYST_ADMIN = 1;
                let SUPPORTER = 2;
                let BACK_OFFICE = 4;
                const data = [];
	            
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }

                const roleUser = this.user.user_sys_auth_div.split(",");

                if(roleUser.filter(x => x == SYST_ADMIN).length > 0 ||
                   roleUser.filter(x => x == BACK_OFFICE).length > 0){
                    res.data.data.map(x => {
                        data.push(x);
                    });
                    this.listSupportPersonOptionBlank = true;
                    this.form.scheduled_support_staff_id = null;
                } else if(roleUser.filter(x => x == SUPPORTER).length > 0) {
                    data.push(this.user);
                    this.listSupportPersonOptionBlank = false;
                    this.form.scheduled_support_staff_id = this.user.id;
                }

                const result = _.uniqBy(data,"id");
                return result;
            })
        },
        async getInsurer() {
            this.listInsurer = await SupportScheduleListService.getInsurer().then(res => {
                return res.data.data;
            })
        },
        find(currentScrollPlace = false) {
            formSearchSupportScheduleList
                .validate(this.form, { abortEarly: false })
                .then(() => {
                    this.errorsSearch = {
                        scheduled_support_date_from: null,
                        scheduled_support_date_to: null,
                        first_support_date_from: null,
                        first_support_date_to: null,
                        patient_name: null,
                        patient_ticket: null,
                        contract_fiscal_year: null
                    };

                    if(!this.In_ValRng_TermOver){
                        this.setIsLoading(true);
                        SupportScheduleListService.getSupportScheduleList(this.form, this.currentPage, this.perPage)
                            .then(res => {
                                this.setIsLoading(false);
                                const { data, status, error } = res;
                                if (status != SUCCESS) {
                                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                    return;
                                }
                                this.currentPage = data.data.current_page;
                                this.totalPage = data.data.last_page;
                                this.results = data.data.data;
                                $(".support-schedule-list").closest(".container-fluid").css("height", this.results.length ? "100%" : "auto");
                                if (screen.width > 760) {
                                    if (!currentScrollPlace) {
                                        window.scrollTo({
                                            behavior: "smooth",
                                            top: 0,
                                        });
                                    }
                                }
                                if (screen.width <= 760) {
                                    if(this.results.length > 0){
                                        $(".support-schedule-list").closest(".container-fluid").css("height", this.results.length ? "200%" : "auto");
                                    }
                                    if (!currentScrollPlace) {
                                        window.scrollTo({
                                            behavior: "smooth",
                                            top: 900,
                                        });
                                    }
                                }
                                if (this.results.length < 1) {
                                    this.notifyNoSearchResults();
                                }
                            })
                            .catch((err) => {
                                this.setIsLoading(false);
	                            this.$toast.error(`[${err}`, TOAST_TIME_OUT);
	                            return;
                            });
                    }
                })
                .catch((err) => {
                    this.setIsLoading(false);
	                this.$toast.error(`[${err}`, TOAST_TIME_OUT);
	                return;
                });
        },
        search(){
            this.currentPage = 1;
            this.find();
        },
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.find();
        },
        filterInsurer(id) {
            return this.listInsurer.find(e => {
                return e.id = id;
            })
        },
        iconInput(segment) {
            if (
                parseInt(segment?.support_exec_div) === this.INITIAL_SUPPORT &&
                segment?.support_stop_flag == false &&
                (parseInt(segment?.support_progress_status) !== this.PLAN) &&
                !this.checkSplitFirstSupportSecond(segment)
            ) {
                return true;
            }
            return false;
        },
        iconAssignTask(segment) {
            if (
                parseInt(segment?.support_exec_div) === this.INITIAL_SUPPORT &&
                segment?.support_stop_flag == false &&
                (parseInt(segment?.support_progress_status) === this.PLAN || parseInt(segment?.support_progress_status) === this.SCHEDULE)
            ) {
                return true;
            }
            return false;
        },
        diffDate(date) {
            return Math.round(Math.abs((parseInt(new Date() - (new Date(date))) / (1000 * 60 * 60 * 24))))
        },
        iconOverDue(segment) {
            if (
                segment?.support_stop_flag == false &&
                (parseInt(segment?.support_progress_status) === this.PLAN || parseInt(segment?.support_progress_status) === this.SCHEDULE || parseInt(segment?.support_progress_status) === this.IN_PROGRESS)
                && segment?.scheduled_support_datetime !== null &&
                parseInt(segment?.health_support_form.support_form_way) === this.FORM_OF_SUPPORT_TELEPHONE &&
                moment(segment?.scheduled_support_date).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")
            ) {
                return true;
            }
            return false;
        },
        iconContinueSupportDecline(segment) {
            if (
                segment?.support_stop_flag == false &&
                (parseInt(segment?.support_progress_status) == this.PLAN || parseInt(segment?.support_progress_status) == this.SCHEDULE || parseInt(segment?.support_progress_status) == this.IN_PROGRESS) &&
                segment?.flow?.support_decline_flag == true
            ) {
                return true;
            }
            return false;
        },
        checkIconPhone(item) {
            if(!item.support_stop_flag && (item.support_progress_status == 20 || item.support_progress_status == 30) && item.segment_status_01){
                return true;
            }
            return false;
        },
        async getListSupportPlace() {
            this.showLoading()
            const response = await DashboardRepository.getListSupportPlace(this.insurerId)
            const {
                status,
                data
            } = response
            if (status === SUCCESS && data) {
                this.hideLoading()
                this.listSupportPlace = data?.data?.map(place => {
                    return {
                        ...place,
                        label: place.item_name,
                        value: place.id
                    }
                })
            } else {
                this.hideLoading()
                this.notifyError("Fetch data failed")
            }

        },
        onHandleCloseDialogAddress() {
            this.isShowDialogAddress = false
            this.addressEdit = null
        },
        taskAssignModal(segment) {
            this.setSegment(segment);
            this.segmentItem = segment;
            this.insurerId = segment.flow.reception.patient.insurer_id;
            this.getListSupportPlace();
            this.isShowDialogAssignTask = true;
        },
        showDialogFn(segment) {
            this.showDialog = true;
            this.showDialogMemo = segment?.support_assign_memo
        },
        onHandleOpenActionAddress(address) {
            if (address) {
                this.addressEdit = {
                    ...address,
                    addressName: address.item_name
                }
            } else {
                this.addressEdit = null
            }
            this.isShowDialogAddress = true
        },
        closeDialogAssignTask() {
            this.segmentItem = null;
            this.isShowDialogAssignTask = false;
        },
        typeOfMessage(segment) {
            if (segment.message_logs[0].sender_id == null) {
                return "(受信)" // RECEIVE
            }
            return "(送信)" // SENT
        },
        getInsuredCardInfo(segment) {

            const patient = segment?.flow?.reception?.patient;

            return patient?.insured_card_number + "/" +
                (patient?.insured_card_symbol ? patient.insured_card_symbol :"-") + "/" +
                (patient?.insured_card_branch_code ? patient.insured_card_branch_code :"-") + "/" +
                this.list_family_division[segment?.flow?.reception?.patient?.person_familiy_div]
            ;
        },
        getPrevSegmentInfo(segment) {
            return {
                support_progress_status: segment.prev_flow_segment_support_progress_status
            };
        }
    },
    destroyed() {
        $(document).unbind()
    }
}
</script>
<style scoped lang="sass">
    .tick
        position: absolute
        top: 60px
        right: 60px
        transform: rotate(-0deg)
        border: 1px solid rgb(0 32 96 / 100%)
        color: rgb(0 32 96 / 100%)
        background: rgba(255,255,255,0.7)
        padding: 2px
        border-radius: 5px
        font-size: 15px
    .header
        background: #cdcdcd
    .action
        a
            text-decoration: none
    .block-segment-gray
        padding-left: 16px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: gray
            border: 1px solid #cbcbcb

    .block-segment-blue
        padding-left: 16px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: #eedcf2
            border: 1px solid #cbcbcb
    .block-segment-pink
        padding-left: 16px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: pink
            border: 1px solid #cbcbcb

    .block-segment-white
      padding-left: 16px
      position: relative
      &:after
          content: ""
          position: absolute
          top: 0
          left: 0
          width: 17px
          height: 100%
          background: #fff
          border: 1px solid #cbcbcb
    .block-segment-green
        padding-left: 16px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: green
            border: 1px solid #cbcbcb
    .cursor-pointer
        cursor: pointer
    .nav-bar-group
        [role=listitem]
            display: block
</style>
