import Repository from "./Repository";
import {ADMIN_REPOSITORY, AUTH_REPOSITORY} from "../../constants";

export default {
    async getSupportScheduleList(data, page, limit) {
        return await Repository.post(`${AUTH_REPOSITORY}/support-schedule-list`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
};
