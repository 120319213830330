<template>
    <v-card>
        <v-container>
            <v-card-title>確認</v-card-title>
            <div class="m-3" v-if="check_text">
            <span v-if="middle_terminate">{{ support_stop_flag ? "途中終了 設定処理を実行します。よろしいですか？" : "途中終了 取消処理を実行します。よろしいですか？" }}</span>
            <span v-else>{{ support_stop_flag ? "初回面接辞退　設定処理を実行します。よろしいですか？" : "初回面接辞退　取消処理を実行します。よろしいですか？" }}</span>
            </div>
            <div class="m-3" v-else>
              <span>{{ proc_name ?? "タスク割当" }}処理を実行します。よろしいですか？</span>
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="$emit('confirmDialog')">{{ $t('common.ok') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogConfirm",
    props: {
        support_stop_flag: [Boolean],
        middle_terminate: [Boolean],
        check_text: [Boolean],
        proc_name: [String],
    },
};
</script>

<style scoped>

</style>
