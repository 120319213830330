<template>
    <v-card>
        <v-container>
            <v-card-title>
                {{ $t("dashboard_support.support_flow.assign_tasks.support_place") }}
            </v-card-title>
            <div class="mt-3 mb-3">
                <label for="address">
                    {{addressEdit ? "支援場所" : "支援場所(新規)"}}
                </label>
                <input id="address" type="text" name="address" v-model="formAddress.address" @input="validateAddress" class="form-control mb-3"/>
<!--                <small id="address-error" v-if="errors.address" class="text-danger">-->
<!--                    {{ errors.address }}-->
<!--                </small>-->
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between">
                <button type="button" class="btn bg-gray"
                        @click="closeModal">キャンセル
                </button>
                <button v-if="!addressEdit" type="button" class="btn btn-success"
                        @click="onHandleActionAddress('create')">追加
                </button>
                <button v-if="addressEdit" type="button" class="btn btn-danger" @click="onHandleActionAddress()">
                    削除
                </button>
                <button v-if="addressEdit" type="button" class="btn btn-primary"
                        @click="onHandleActionAddress('update')">変更
                </button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
import { DASHBOARD, SUCCESS } from "@/constants";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { schemaValidationSupportAddress } from "@/common/schemaValidation";
import mixins from "@/mixins"

const DashboardRepository = RepositoryFactory.get(DASHBOARD);

export default {
    name: "ActionAddress",
    props: {
        addressEdit: {
            type: [Object],
            default: null
        },
        insurerId: null
    },
    mixins: [
        mixins
    ],
    created() {
        this.formAddress.id = this.addressEdit?.id;
        this.formAddress.address = this.addressEdit?.addressName;
    },
    data() {
        return {
            formAddress: {
                id: null,
                address: null
            },
            errors: {
                address: null
            }
        };
    },
    watch: {
        addressEdit(newVal) {
            this.formAddress.id = newVal?.id;
            this.formAddress.address = newVal?.addressName;
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeDialog");
        },
        async onHandleActionAddress(action = "delete") {
            if (action === "delete") {
                return DashboardRepository.deleteSupportPlace(this.formAddress)
                    .then(response => {
                        this.notifySuccess(`支援場所[${this.formAddress.address}]の削除が完了しました。`)
                        this.$emit("closeDialog");
                        this.$emit("actionSuccess")
                    })
                    .catch(err => {
                        this.$toast.open({
                            // message: `${ action } address failed: ${err.message}`,
                            message: err.message,
                            type: "error",
                        });
                    });
            }
            schemaValidationSupportAddress.validate(this.formAddress, { abortEarly: false })
                .then(async () => {
                    this.errors = {};
                    if (this.formAddress.id) {
                        return DashboardRepository.updateSupportPlace({
                            id: this.formAddress.id,
                            item_name: this.formAddress.address,
                            insurer_code: this.insurerId
                        })
                            .then(response => {
                                this.notifySuccess(`支援場所[${this.formAddress.address}]への変更が完了しました。`)
                                this.formAddress.address = "";
                                this.$emit("closeDialog");
                                this.$emit("actionSuccess")
                            })
                            .catch(err => {
                                this.formAddress.address = "";
                                this.notifyError(`Update address failed: ${err.message}`)
                            });
                    }
                    return DashboardRepository.createSupportPlace({
                        item_name: this.formAddress.address,
                        insurer_code: this.insurerId
                    })
                        .then(response => {
                            this.notifySuccess(`支援場所[${this.formAddress.address}]の登録が完了しました。`)
                            this.$emit("closeDialog");
                            this.$emit("actionSuccess")
                            this.formAddress.address = "";
                            // return this.processActionSuccess(response, "Create");
                        })
                        .catch(err => {
                            if (err.messageCode == "0800") {
                                this.notifyError(`支援場所[${this.formAddress.address}]は既に存在します。`)
                            } else {
                                this.notifyError(`Create address failed: ${err.message}`)
                            }
                            // this.$emit("closeDialog");
                            // this.$emit("actionSuccess")
                            this.formAddress.address = "";
                        });

                })
                .catch(err => {
                    this.notifyError(err.errors[0])
                    if (err && err.inner && err.inner.length) {
                        err.inner.forEach(error => {
                            this.errors[error.path] = error.message;
                        });
                    }
                });

        },
        processActionSuccess(response, action) {
            const {
                status
            } = response;
            this.showLoading()
            if (status === SUCCESS) {
                this.$emit("closeDialog");
                this.$emit("actionSuccess")
                this.notifySuccess(`${ action } address success`)
            } else {
                this.hideLoading()
                this.notifyError(`${ action } address failed`)
            }
        },
        validateAddress() {
            schemaValidationSupportAddress.validateAt("address", this.formAddress)
                .then(() => {
                    this.errors.address = null;
                })
                .catch(err => {
                    this.errors.address = err.message;
                });
        }
    },
};
</script>

<style scoped>

</style>
