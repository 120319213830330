<template>
    <v-card class="mb-3">
        <v-card-title>
            {{ $t('dashboard_support.support_flow.assign_tasks.title') }}
        </v-card-title>
        <div class="container">
            <div class="d-flex justify-content-end" v-if="this.segmentCurrent?.support_assignor_id != null">
                <button v-if="segmentItem?.support_progress_status == 20 && checkClearAssignTask" type="button" class="btn btn-danger" @click="showDialogDelete=true">{{ $t('dashboard_support.support_flow.assign_tasks.quota_clear') }}</button>
            </div>
            <template v-if="useOutcomeEval && segmentItem?.support_exec_div == INITIAL_SUPPORT && segmentItem.support_flow_seq==0">
                <div>
                    <v-checkbox label="分割実施" v-model="formRequest.split_first_support_flag" />
                </div>
            </template>
            <div class="mb-4">
                <label for="requester" class="mb-1">依頼者 <span class="text-danger">*</span></label>
                <select id="requester" class="form-select" v-model="formRequest.support_assignor_id" @change="validate('support_assignor_id')">
                    <option v-for="requester in listRequester" :key="requester.id" :value="requester.id">
                        {{ requester.label }}
                    </option>
                </select>
                <span class="text-danger" v-if="validate_messages.support_assignor_id">{{ validate_messages.support_assignor_id }}</span>
            </div>
            <div class="mb-4">
                <label for="scheduled_date_time" class="mb-1">{{ $t('dashboard_support.support_flow.assign_tasks.scheduled_date_time') }} <span class="text-danger">*</span></label>
                <div class="row">
                    <div class="col-9">
                        <date-picker
                                autocomplete="off"
                                class="w-100"
                                type="date"
                                value-type="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                v-model="formRequest.scheduled_support_date"
                                @change="validate('scheduled_support_date')"
                                input-class="form-control"
                        >
                        </date-picker>
                    </div>
                    <div class="col-3">
                        <date-picker
                                autocomplete="off"
                                class="w-100"
                                v-model="formRequest.scheduled_support_time"
                                type="time"
                                value-type="HH:mm"
                                format="HH:mm"
                                @change="validate('scheduled_support_date')"
                                input-class="form-control"
                        >
                        </date-picker>
                    </div>


                </div>
                <span class="text-danger" v-if="validate_messages.scheduled_support_date">{{ validate_messages.scheduled_support_date }}</span>
            </div>
            <div class="mb-4">
                <label for="support_person" class="mb-1">{{ $t('dashboard_support.support_flow.assign_tasks.support_person') }} <span class="text-danger">*</span></label>
                <select id="support_person" class="form-select" v-model="formRequest.scheduled_support_staff_id" @change="validate('scheduled_support_staff_id')">
                    <option :value="null"></option>
                    <option v-for="person in listSupportPerson" :key="person.value" :value="person.value">
                        {{ person.label }}
                    </option>
                </select>
                <span class="text-danger" v-if="validate_messages.scheduled_support_staff_id">{{ validate_messages.scheduled_support_staff_id }}</span>
            </div>
            <div class="mb-4" v-if="checkSupportFirst(segmentCurrent)">
                <label for="support_place" class="mb-1">{{ $t('dashboard_support.support_flow.assign_tasks.support_place') }}</label>
                <div class="d-flex justify-content-between align-items-center gap-3">
                    <select id="support_place" class="form-select" v-model="formRequest.scheduled_support_location">
                        <option value=""></option>
                        <option v-for="place in listSupportPlace" :key="place.value" :value="place.value">
                            {{ place.label }}
                        </option>
                    </select>
                    <v-icon class="c-pointer" :disabled="formRequest.scheduled_support_location == null" @click="$emit('openActionAddress', addressSupport)">mdi-pencil-box-outline</v-icon>
                    <v-icon class="c-pointer" @click="$emit('openActionAddress', null)">mdi-plus-circle-outline</v-icon>
                </div>
            </div>
            <div class="mb-4">
                <label for="memo" class="mb-1">{{ $t('dashboard_support.support_flow.assign_tasks.memo') }}</label>
                <textarea id="memo" class="input-group form-control" v-model="formRequest.support_assign_memo" />
            </div>
        </div>
        <v-card-actions class="d-flex align-content-center justify-content-between">
            <button type="button" class="btn bg-gray" @click="closeDialog">{{ $t('common.cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="assignTaskConfirm">{{ $t('common.setting') }}</button>
        </v-card-actions>


        <Dialog :isShow="isDialogConfirm" persistent :maxWidth="600">
            <DialogConfirm @confirmDialog="assignTask" @closeDialog="assignTaskClose" />
        </Dialog>
      <Dialog :maxWidth="600" :isShow="showDialogDelete">
        <v-card>
          <v-container>
            <v-card-title>確認</v-card-title>
            <div class="m-3">タスク割当 クリア処理を実行します。よろしいですか？
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="showDialogDelete = false"
              >
                キャンセル
              </button>
              <button @click="clearAssignTask(); showDialogDelete = false" type="button" class="btn btn-primary float-right">
                設定
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>

    </v-card>
</template>

<script>
import moment from "moment";
import {ADMIN_REPOSITORY, DASHBOARD, SUCCESS} from "@/constants";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import {mapGetters} from "vuex";
import mixins from "@/mixins"
import Dialog from "@/components/common/Dialog";
import DialogConfirm from "@/components/dashboard/DialogConfirm";
import { assignTaskValidate } from "@/common/schemaValidation";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import Repository from "@/services/api/Repository";

const DashboardRepository = RepositoryFactory.get(DASHBOARD)
var Vue = require("vue");

export default {
    name: "AssignTasks",
    components: {
        DatePicker,
        Dialog,
        DialogConfirm,
    },
    props: {
        listSupportPlace: {
            type: Array,
            default: () => []
        },
        segmentItem: null,
        open: null,
        scheduledFirstSupportDatetimeAssign: null,
        useOutcomeEval: null,
        openSource: {
            type: String,
            default: () => "Dashboard"
        }, // 起動元 "Dashboard":デフォルト/ダッシュボード、 "SupportScheduleList":支援予定一覧
    },
    mixins: [
        mixins
    ],
    data() {
        return {
            showDialogDelete: false,
            isDialogConfirm: false,
            listRequester: [],
            listSupportPerson: [],
            formRequest: {
                scheduled_support_datetime: null,
                scheduled_support_date: null,
                scheduled_support_time: null,
                scheduled_support_location: null,
                scheduled_support_staff_id: null,
                support_assignor_id: null,
                support_assign_memo: null,
                split_first_support_flag: false,
            },
            supportExecDivCurrent: null,
            supportExecDivFirst: 10,
            segmentCurrent: null,
            validate_messages: [],
            checkClearAssignTask: false,
            allUserArr: [],
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,
        }
    },
    async created() {
        await this.getSegmentDetail(this.segmentItem?.id);
        await this.getListSupportPerson();
        await this.getListRequester();
    },
    computed: {
        ...mapGetters({
            // segment: "dashboard/getSegment"
            user: "common/getUser"
        }),
        addressSupport() {
            return this.listSupportPlace.find(place => place.value === this.formRequest.scheduled_support_location)
        }
    },
    watch: {
        async segmentItem(data){
            if (data) {
                await this.getSegmentDetail(data?.id);
                await this.getListSupportPerson();
                await this.getListRequester();
            }
        }
    },
    methods: {
        async getSegmentDetail(segment_id) {
            try {
                await DashboardRepository.getSegmentDetail(segment_id).then(res => {
                    this.segmentCurrent = res.data.data;
                    this.supportExecDivCurrent = this.segmentCurrent?.support_exec_div
                    if (this.listSupportPlace && this.listSupportPlace.length) {
                        this.formRequest.supportPlace = this.listSupportPlace[0]["id"]
                    }

                    this.formRequest.scheduled_support_datetime = this.segmentCurrent?.scheduled_support_datetime;
                    if (this.supportExecDivCurrent == this.supportExecDivFirst) {
                        if (this.scheduledFirstSupportDatetimeAssign) {
                            // case scheduled first support datetime assign is not null
                            let dateTimeScheduledFirst = moment(new Date(this.scheduledFirstSupportDatetimeAssign));
                            this.formRequest.scheduled_support_date = dateTimeScheduledFirst.format("YYYY-MM-DD");
                            this.formRequest.scheduled_support_time = dateTimeScheduledFirst.format("HH:mm");
                        }

                        if (this.segmentCurrent?.plan_support_date) {
                            // case plan_support_date is not null
                            this.formRequest.scheduled_support_date = this.segmentCurrent?.plan_support_date;
                            this.formRequest.scheduled_support_time = this.segmentCurrent?.plan_support_time;

                        }
                        if (this.segmentCurrent?.scheduled_support_date) {
                            // case scheduled_support_date is not null
                            this.formRequest.scheduled_support_date = this.segmentCurrent?.scheduled_support_date;
                            this.formRequest.scheduled_support_time = this.segmentCurrent?.scheduled_support_time;
                        }
                    } else {
                        this.formRequest.scheduled_support_date = this.segmentCurrent?.scheduled_support_date;
                        this.formRequest.scheduled_support_time = this.segmentCurrent?.scheduled_support_time;
                    }
                    this.formRequest.scheduled_support_location = (typeof this.segmentCurrent?.scheduled_support_location == "object") ? this.segmentCurrent?.scheduled_support_location?.id : null;

                    if ((this.isBackOffice() || this.isSystemAdmin()) && !this.segmentCurrent?.scheduled_support_staff_id) {
                        this.formRequest.scheduled_support_staff_id = null;
                    } else if (this.isSupporter() && !this.segmentCurrent?.scheduled_support_staff_id) {
                        this.formRequest.scheduled_support_staff_id = this.currentUserID();
                    } else {
                        this.formRequest.scheduled_support_staff_id = this.segmentCurrent?.scheduled_support_staff_id;
                    }

                    this.formRequest.support_assignor_id = this.segmentCurrent?.support_assignor_id || this.currentUserID()
                    this.formRequest.support_assign_memo = this.segmentCurrent?.support_assign_memo;
                    this.formRequest.split_first_support_flag = this.segmentCurrent?.split_first_support_flag;
                })
            } catch (e) {
                console.error(e);
            }
        },
        closeDialog() {
            this.isDialogConfirm = false;
            this.validate_messages = [];
            this.formRequest = {
                scheduled_support_datetime: null,
                scheduled_support_date: null,
                scheduled_support_time: null,
                scheduled_support_location: null,
                scheduled_support_staff_id: null,
                support_assignor_id: null,
                support_assign_memo: null,
                split_first_support_flag: false,
            };
            this.$emit("closeDialog")
        },
        getListRequester() {
            DashboardRepository.getListRequester({
                health_support_flow_segment_id: this.segmentItem?.id,
            })
                .then(response => {
                    const {
                        data,
                        status
                    } = response

                    if (status === SUCCESS) {
                        const requester = [];
                        data?.data?.map((x) => {
                            x.label = "[" + ("00000000"+x.id).substr(-7) + "]" + x.user_name_disp;
                            x.value = x.id;
                            requester.push(x)
                        })

                        if(this.allUserArr.length > 0){
                            const support_assignor_id = this.allUserArr.filter(x => x.id == this.formRequest.support_assignor_id);
                            if(support_assignor_id.length > 0){
                                let a = support_assignor_id[0];
                                a.label = "[" + ("00000000"+a.id).substr(-7) + "]" + a.user_name_disp;
                                a.value = a.id;
                                requester.push(a);
                            }
                        }

                        this.listRequester = _.uniqBy(requester,"id");
                    } else {
                        this.notifyError("Fetch list requester failed")
                    }
                })
                .catch((err) => {
                    this.notifyError(`Fetch list requester failed: Error: ${err.message}`)
                })
        },
        getListSupportPerson() {
            DashboardRepository.getListSupportPerson().then(res => {
                const {
                    data,
                    status
                } = res

                if (status === SUCCESS) {
                    let SYST_ADMIN = 1;
                    let SUPPORTER = 2;
                    let BACK_OFFICE = 4;
                    const data = [];

                    // 権限取得.
                    const roleUser = this.user.user_sys_auth_div.split(",");
                    if(this.isSystemAdminOrBackOffice()){
                        // タスク割当クリア可能.
                        this.checkClearAssignTask = true;
                    }else{
                        // タスク割当クリア不可.
                        this.checkClearAssignTask = false;
                    }

                    if(this.isSystemAdminOrBackOffice()){
                        res.data.data.map(x => {
                            const auth_divs = x.user_sys_auth_div.split(",");
                            if(auth_divs.filter(x => x == SUPPORTER).length > 0){
                                x.label = "[" + ("00000000"+x.id).substr(-7) + "]" + x.user_name_disp;
                                x.value = x.id;
                                data.push(x);
                            }
                        });
                    } else if(this.isSupporter()){
                        this.user.label = "[" + ("00000000"+this.user.id).substr(-7) + "]" + this.user.user_name_disp;
                        this.user.value = this.user.id;
                        data.push(this.user);

                        const user_scheduled_support_staff_id = res.data.data.filter(x => x.id == this.formRequest.scheduled_support_staff_id);
                        if(user_scheduled_support_staff_id.length > 0){
                            let a = user_scheduled_support_staff_id[0];
                            a.label = "[" + ("00000000"+a.id).substr(-7) + "]" + a.user_name_disp;
                            a.value = a.id;
                            data.push(a);
                        }
                    }

                    this.allUserArr = res.data.data;
                    this.listSupportPerson = _.uniqBy(data,"id");
                } else {
                    this.notifyError("Fetch list support person failed")
                }
            })
                .catch((err) => {
                    this.notifyError(`Fetch list support person failed: Error: ${err.message}`)
                })
        },
        setupFormRequest() {
            this.formRequest = {
                requester: null,
                dateSchedule: moment().format("YYYY-MM-DD"),
                timeSchedule: "00:00",
                supportPerson: null,
                supportPlace: null,
                memo: null
            }
        },
        async clearAssignTask() {
            await DashboardRepository.clearAssignTask(this.segmentCurrent.id).then(res => {
                this.$emit("closeDialog")
                if (res.data.data) {
                    this.$root.$emit(`${this.openSource}.ReloadDataReception`);
                    this.notifySuccess("タスク割当のクリアが完了しました。")
                } else {
                    this.notifyError("エラーメッセージ")
                }
            })
        },
        async assignTaskConfirm() {
            assignTaskValidate.validate(this.formRequest, {abortEarly: false})
                .then(() => {
                    this.isDialogConfirm = true;
                }).catch(error => {
                    if (error && error.inner && error.inner.length) {
                        error.inner.forEach(err => {
                            Vue.set(this.validate_messages, err.path, err.message)
                        })
                    }
                })

        },
        async assignTaskClose() {
            this.isDialogConfirm = false;
        },
        async assignTask() {
            await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${this.segmentCurrent.id}`).then(response => {
                this.segment = response.data.data;
                if(this.segment.support_stop_flag === 0 &&
                  [this.PLAN, this.SCHEDULE, this.IN_PROGRESS].indexOf(parseInt(this.segment.support_progress_status)) >= 0) {
                    DashboardRepository.assignTask(this.segmentCurrent.id, this.formRequest).then(res => {
                        this.$emit("closeDialog")
                        this.isDialogConfirm = false;
                        if (res.status === false) {
                            if (res.error.error === "support_and_schedule_exists_data") {
                                this.notifyError("指定されたユーザは、支援依頼者として設定できません。")
                                this.notifyError("指定されたユーザは、支援担当者として設定できません。")
                            } else if (res.error.error === "support_exists_data") {
                                this.notifyError("指定されたユーザは、支援依頼者として設定できません。")
                            } else if (res.error.error === "schedule_exists_data") {
                                this.notifyError("指定されたユーザは、支援担当者として設定できません。")
                            } else {
                                this.notifyError("エラーメッセージ")
                            }
                        } else {
                            if (res.data.data) {
                                this.$root.$emit(`${this.openSource}.ReloadDataReception`);
                                this.notifySuccess("タスク割当の登録が完了しました。")
                            }
                        }
                    })
                } else {
                    this.$toast.open({
                        message: "[E0701]入力エラー<br>タスク割当 設定の実施ができないステータスです。",
                        type: "error",
                    });
                }
            })

        },
        validate(field) {
            assignTaskValidate
                .validateAt(field, this.formRequest)
                .then(() => {
                    // this.validate_messages.field = "";
                    Vue.set(this.validate_messages, field, "")
                })
                .catch((err) => {
                    // this.validate_messages.field = err.message;
                    Vue.set(this.validate_messages, field, err.message)
                });
        },
    }
};
</script>

<style lang="sass" scoped>
.bg-gray
    background-color: #dddddd
.height-50
    height: 50px

</style>
